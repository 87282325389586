<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)">
      <ion-header>
        <ion-toolbar>
          <ion-title>Medical History</ion-title>
        </ion-toolbar>
      </ion-header>
      
        <ion-card class="main-card">
          <ion-card-header>
            <ion-card-title>Medical History</ion-card-title>
            <p>Your Medical History helps inform our Doctors</p>
          </ion-card-header>

          <div class="carousel-component">
            <h5>Category</h5>
            <div class="carousel">
              <img src="/assets/icon/dropdown.svg" height="14" class="prev" />
              <!-- carousel -->
              <ion-slides>
                <ion-slide class="selected" @click="allergies($event)">
                  <div class="slide">
                    <span
                      >Allergies to <br />
                      Medication</span
                    >
                  </div>
                </ion-slide>

                <ion-slide class="larger-slides" @click="hospitalAdmissions($event)">
                  <span>Previous Hospital Admissions</span>
                </ion-slide>

                <!-- <ion-slide @click="surgeries($event)">
                  <span
                    >Previous <br />
                    Surgeries</span
                  >
                </ion-slide> -->

                <ion-slide @click="illnesses($event)">
                  <span
                    >Current <br />
                    Illnesses</span
                  >
                </ion-slide>

                <ion-slide class="larger-slides" @click="chronic($event)">
                  <span>Current Chronic Medications Used Daily</span>
                </ion-slide>

                <ion-slide @click="vaccinations($event)">
                  <span>Vaccinations</span>
                </ion-slide>
              </ion-slides>

              <img src="/assets/icon/dropdown.svg" height="14" class="next" />
            </div>
          </div>
          <ion-card-content class="allergies selected-history">
            <allergies-history ></allergies-history>
          </ion-card-content>

          <ion-card-content class="hospital-admissions unselected-history">
            <hospital-admissions-history ></hospital-admissions-history>
          </ion-card-content>

          <!-- <ion-card-content class="surgeries unselected-history">
            <surgeries-history ></surgeries-history>
          </ion-card-content> -->

          <ion-card-content class="illnesses unselected-history">
            <illnesses-history ></illnesses-history>
          </ion-card-content>

          <ion-card-content class="chronic unselected-history">
            <chronic-history ></chronic-history>
          </ion-card-content>

          <ion-card-content class="vaccinations unselected-history">
            <vaccinations-history ></vaccinations-history>
          </ion-card-content>

        </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import AllergiesHistory from "@/components/patient/allergiesHistory.vue";
import HospitalAdmissionsHistory from "@/components/patient/hospitalAdmissionsHistory.vue";
// import SurgeriesHistory from "@/components/patient/surgeriesHistory.vue";
import IllnessesHistory from "@/components/patient/illnessesHistory.vue";
import ChronicHistory from "@/components/patient/chronicHistory.vue";
import VaccinationsHistory from "@/components/patient/vaccinationsHistory.vue";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/vue";
import {
  closeCircleOutline
} from "ionicons/icons";

export default {
  name: "Medical History",
  components: {
    IonContent,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    AllergiesHistory,
    HospitalAdmissionsHistory,
    // SurgeriesHistory,
    IllnessesHistory,
    ChronicHistory,
    VaccinationsHistory
  },
  setup() {
    return {
      closeCircleOutline
    };
  },
  data() {
    return {
      data: null
    };
  },
  computed: {
    medical_history() {
      return this.$store.state.medical_history;
    },
    user() {
      return this.$store.state.user;
    },
  },
  ionViewDidEnter() {
    this.$store.dispatch("MedicalHistory", this.user.id).then();
  },
  methods: {
    allergies(e){
      document.querySelector('.selected').classList.remove('selected');

      var target = e.currentTarget;
      target.classList.add('selected');

      document.querySelector('.selected-history').classList.add('unselected-history');
      document.querySelector('.selected-history').classList.remove('selected-history');

      document.querySelector('.allergies').classList.remove('unselected-history');
      document.querySelector('.allergies').classList.add('selected-history');
    },
    hospitalAdmissions(e){
      document.querySelector('.selected').classList.remove('selected');

      var target = e.currentTarget;
      target.classList.add('selected');

      document.querySelector('.selected-history').classList.add('unselected-history');
      document.querySelector('.selected-history').classList.remove('selected-history');
      
      document.querySelector('.hospital-admissions').classList.remove('unselected-history');
      document.querySelector('.hospital-admissions').classList.add('selected-history');
    },
    // surgeries(e){
    //   document.querySelector('.selected').classList.remove('selected');

    //   var target = e.currentTarget;
    //   target.classList.add('selected');

    //   document.querySelector('.selected-history').classList.add('unselected-history');
    //   document.querySelector('.selected-history').classList.remove('selected-history');
      
    //   document.querySelector('.surgeries').classList.remove('unselected-history');
    //   document.querySelector('.surgeries').classList.add('selected-history');
    // },
    illnesses(e){
      document.querySelector('.selected').classList.remove('selected');

      var target = e.currentTarget;
      target.classList.add('selected');

      document.querySelector('.selected-history').classList.add('unselected-history');
      document.querySelector('.selected-history').classList.remove('selected-history');
      
      document.querySelector('.illnesses').classList.remove('unselected-history');
      document.querySelector('.illnesses').classList.add('selected-history');
    },
    chronic(e){
      document.querySelector('.selected').classList.remove('selected');

      var target = e.currentTarget;
      target.classList.add('selected');

      document.querySelector('.selected-history').classList.add('unselected-history');
      document.querySelector('.selected-history').classList.remove('selected-history');
      
      document.querySelector('.chronic').classList.remove('unselected-history');
      document.querySelector('.chronic').classList.add('selected-history');
    },
    vaccinations(e){
      document.querySelector('.selected').classList.remove('selected');

      var target = e.currentTarget;
      target.classList.add('selected');

      document.querySelector('.selected-history').classList.add('unselected-history');
      document.querySelector('.selected-history').classList.remove('selected-history');
      
      document.querySelector('.vaccinations').classList.remove('unselected-history');
      document.querySelector('.vaccinations').classList.add('selected-history');
    }
  }
};
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-primary);
}

.main-card {
  margin-top: 12px;
}

p {
  font-size: 16px;
  line-height: 24px;
}

.actions {
  text-align: left;
  padding-left: 29px;
  padding-right: 28px;
  font-size: 10px;
  line-height: 15px;
}

.delete {
  filter: invert(13%) sepia(46%) saturate(7443%) hue-rotate(355deg)
    brightness(92%) contrast(115%);
  float: right;
}

ion-slide {
  height: 69px;
  max-width: 115px;
  font-size: 14px;
  line-height: 21px;
  background: var(--ion-color-medium);
  border-radius: 3%;
  margin-right: 12px;
  color: #777777;
}

.carousel {
  padding-top: 10px;
  padding-bottom: 10px;
}

ion-slides {
  width: 80%;
}

.selected {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.prev {
  transform: rotate(90deg);
  float: left;
  margin-top: 30px;
}

.next {
  transform: rotate(-90deg);
  float: right;
  margin-top: -41px;
}

.larger-slides {
  max-width: 193px;
}

h5 {
  line-height: 27px;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
}

.carousel-component {
  padding-left: 17px;
  padding-right: 17px;
}

.selected-history {
  display: block;
}

.unselected-history {
  display: none;
}
</style>