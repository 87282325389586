<template>
    <h5>Add Medication</h5>
    <form ref="form" @submit.prevent="submit">     
      <InputText id="medicine" type="text" v-model="form.medicine" required placeholder="Allergies" style="color: #777777;border: none;border-bottom: solid 1px;"/>
   
      <ion-button size="small" shape="round" type="submit" style="float: right;">Add</ion-button>
    </form>
    <h5>Medications you are <br>allergic to</h5>
    <ion-chip outline color="danger" style="float: left" v-for="allergy in history.allergies" :key="allergy">
        <ion-label>{{allergy.medicine}}</ion-label> <img @click.prevent="deleteItem(allergy.id)" src="/assets/icon/close-app.svg" width="18" style="filter: invert(11%) sepia(98%) saturate(4429%) hue-rotate(0deg) brightness(88%) contrast(118%);margin-left: 11px;"/>
    </ion-chip>
</template>

<script>
export default {
  name: "allergiesHistory",

  data() {
    return {
      form: {
        medicine: null,
        user_id: null,
        category: 'Allergy'
      },
      form2: {
        id: null,
        user_id: null,
        selected: 'Allergy'
      },
    };
  },
  computed: {
    history() {
      return this.$store.state.medicalHistory.medicalHistory;
    },
    user() {
      return this.$store.state.user;
    },
  },
  ionViewDidEnter() {
    this.form.user_id = this.user.id;
    this.form2.user_id = this.user.id;
  },
  methods: {
    submit: function () {
      this.$store.dispatch("AddMedicalHistory", this.form);
    },
    deleteItem(id){
      this.form2.id = id;
      this.$store.dispatch("DeleteMedicalHistory", this.form2);
    }
  },
};

</script>

<style scoped>
h5 {
  line-height: 27px;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
}

</style>