<template>
    <router-link :to="{ path: '/member/medicalhistory/addhospitaladmission'}" class="router-button" style="float: right;">Add</router-link>
    <h5 style="width: 60%">Your previous hospital admissions</h5> 

    <DataTable
        :value="history.hospital_admissions"
        responsiveLayout="scroll"
        :paginator="true"
        :rows="10"
        paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink "
        :rowsPerPageOptions="[5, 10, 15, 25, 50, 75]"
        rowsPerPageTemplate="Rows per page"
        currentPageReportTemplate="{first}-{last} of {totalRecords}"
    >
      <Column
        field="date"
        header="Date of Admission"
        :sortable="true"
      ></Column>
      <Column field="reason" header="Reason for Admission" :sortable="true"></Column>
      <Column field="length" header="Length of Admission" :sortable="true"></Column>
      <Column>
        <template #body="{ data }">
          <img @click.prevent="deleteItem(data.id)" src="/assets/icon/close-app.svg" width="18" style="filter: invert(11%) sepia(98%) saturate(4429%) hue-rotate(0deg) brightness(88%) contrast(118%);"/>
        </template>
      </Column>
    </DataTable>
</template>

<script>
export default {
  name: "hospitalAdmissionsHistory",
  
  data() {
    return {
      form: {
        id: null,
        user_id: null,
        selected: 'Hospital Admission'
      },
    };
  },
  computed: {
    history() {
      return this.$store.state.medicalHistory.medicalHistory;
    },
    user() {
      return this.$store.state.user;
    },
  },
  ionViewDidEnter() {
    this.form.user_id = this.user.id;
  },
  methods: {
    deleteItem(id){
      this.form.id = id;
      this.$store.dispatch("DeleteMedicalHistory", this.form);
    }
  },
};
</script>

<style scoped>

h5 {
  line-height: 27px;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
}

th{
  font-size: 14px !important;
}

</style>